class NotaDiariaHandle {

    formToJso(formData) {
        return {
            id: formData.id,
            titulo: formData.titulo,
            subtitulo: formData.subtitulo,
            conteudo: formData.conteudo,
            status: formData.status,
        };
    }

}

export default new NotaDiariaHandle();